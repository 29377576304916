import { getCurrentAppUser } from "@/firebase";
import Home from "@/views/Home.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
    beforeEnter: async (to, from, next) => {
      if (await getCurrentAppUser()) {
        next();
      } else {
        next("/auth/login");
      }
    },
    children: [
      {
        path: "/",
        redirect: "calendar",
        name: "My Schedule"
      },
      {
        path: "calendar",
        name: "Calendar",
        component: () =>
          import(/* webpackChunkName: "Calendar" */ "@/views/Calendar.vue")
      },
      {
        path: "programs",
        name: "Programs",
        component: () =>
          import(/* webpackChunkName: "Programs" */ "@/views/Programs.vue"),
        children: [
          {
            path: "directory",
            name: "Programs-Directory",
            component: () =>
              import(
                /* webpackChunkName: "Programs-Directory" */ "@/views/programs/Directory.vue"
              )
          },
          {
            path: "calendar",
            name: "Programs-Calendar",
            component: () =>
              import(/* webpackChunkName: "Calendar" */ "@/views/Calendar.vue")
          },
          {
            path: "create",
            name: "Create-Program",
            component: () =>
              import(
                /* webpackChunkName: "Programs-Create" */ "@/views/programs/Create.vue"
              )
          },
          {
            path: "view/:id",
            name: "View-Program",
            component: () =>
              import(
                /* webpackChunkName: "Programs-View" */ "@/views/programs/View.vue"
              )
          },
          {
            path: "payment/:id",
            name: "Payments - Programs",
            component: () =>
              import(
                /* webpackChunkName: "Payment" */ "@/views/programs/RunProgramPayment.vue"
              )
          }
        ]
      },
      {
        path: "profile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "@/views/Profile.vue"),
        children: [
          {
            path: "current",
            alias: ["/"],
            name: "Your Profile",
            component: () =>
              import(
                /* webpackChunkName: "CurrentUserProfile" */ "@/views/profile/CurrentUserProfile.vue"
              )
          },
          {
            path: "payments",
            name: "Payment Settings",
            component: () =>
              import(
                /* webpackChunkName: "UserPaymentMethods" */ "@/views/profile/UserPaymentMethods.vue"
              ),
            children: [
              {
                path: "added",
                name: "Payment Method added",
                component: () =>
                  import(
                    /* webpackChunkName: "PaymentMethodAdded" */ "@/views/profile/PaymentMethodAdded.vue"
                  )
              }
            ]
          },
          {
            path: "payments-reports",
            name: "Payment Reports",
            component: () =>
              import(
                /* webpackChunkName: "ProfilePaymentsReport" */ "@/views/profile/ProfilePaymentsReport.vue"
              )
          },
          {
            path: "memberships",
            name: "User Membership",
            component: () =>
              import(
                /* webpackChunkName: "Membership" */ "@/views/profile/UserMembership.vue"
              ),
            children: [
              {
                path: "subscribe/:membershipId",
                name: "Memberships - Subscribe",
                component: () =>
                  import(
                    /* webpackChunkName: "Subscribe" */ "@/views/profile/Subscribe.vue"
                  )
              },
              {
                path: "unsubscribe/:membershipId",
                name: "Memberships - Unsubscribe",
                component: () =>
                  import(
                    /* webpackChunkName: "Unsubscribe" */ "@/views/profile/Unsubscribe.vue"
                  )
              }
            ]
          },
          {
            path: "change-password",
            name: "Change password",
            component: () =>
              import(/* webpackChunkName: "UnReady" */ "@/views/UnReady.vue")
          },
          {
            path: "terms-and-conditions",
            name: "Terms and Conditions",
            component: () =>
              import(/* webpackChunkName: "Terms" */ "@/views/Terms.vue")
          },
          {
            path: "payment-terms",
            name: "Payment Terms",
            component: () =>
              import(
                /* webpackChunkName: "Terms" */ "@/views/PaymentTermsPage.vue"
              )
          }
        ]
      },
      {
        path: "coaches",
        component: () =>
          import(/* webpackChunkName: "Coaches" */ "@/views/Coaches.vue"),
        children: [
          {
            path: "/",
            name: "Coaches",
            component: () =>
              import(
                /* webpackChunkName: "Coach-Profile" */ "@/views/coach/CoachesDirectory.vue"
              ),
            children: [
              {
                path: "profile/:id",
                component: () =>
                  import(
                    /* webpackChunkName: "Coach-Profile" */ "@/views/coach/CoachProfile.vue"
                  )
              }
            ]
          },
          {
            path: "calendar/:userId",
            name: "Coach - Calendar",
            component: () =>
              import(/* webpackChunkName: "Calendar" */ "@/views/Calendar.vue"),
            props: {
              calendarType: "Coach"
            }
          }
        ]
      },
      {
        path: "users",
        component: () =>
          import(/* webpackChunkName: "Users" */ "@/views/Users.vue"),
        name: "Users",
        alias: ["players"],
        children: [
          {
            path: "directory",
            alias: "/",
            component: () =>
              import(
                /* webpackChunkName: "UsersDirectory" */ "@/views/users/UsersDirectory.vue"
              ),
            name: "Users - List",
            children: [
              {
                path: "profile/:id",
                name: "User Profile",
                component: () =>
                  import(
                    /* webpackChunkName: "UsersProfile" */ "@/views/users/UserProfile.vue"
                  )
              },
              {
                path: "membership/:id",
                name: "User Membership",
                component: () =>
                  import(
                    /* webpackChunkName: "SelectedUserMembership" */ "@/views/users/SelectedUserMembership.vue"
                  ),
                children: [
                  {
                    path: "subscribe/:membershipId",
                    name: "Memberships - Subscribe User",
                    component: () =>
                      import(
                        /* webpackChunkName: "Subscribe" */ "@/views/profile/Subscribe.vue"
                      )
                  },
                  {
                    path: "unsubscribe/:membershipId",
                    name: "Memberships - Unsubscribe User",
                    component: () =>
                      import(
                        /* webpackChunkName: "Unsubscribe" */ "@/views/profile/Unsubscribe.vue"
                      )
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "courts",
        component: () =>
          import(/* webpackChunkName: "UnReady" */ "@/views/UnReady.vue")
      },
      {
        path: "add-profile",
        name: "Add Profile",
        component: () =>
          import(
            /* webpackChunkName: "AddProfile" */ "@/views/users/AddProfile.vue"
          )
      },
      {
        path: "settings",
        name: "Settings",
        component: () =>
          import(/* webpackChunkName: "Settings" */ "@/views/Settings.vue"),
        children: [
          {
            path: "account",
            name: "Settings-Account",
            component: () =>
              import(
                /* webpackChunkName: "Settings-Account" */ "@/views/settings/SettingsAccount.vue"
              ),
            children: [
              {
                path: "return",
                name: "Settings-Account-Return",
                component: () =>
                  import(
                    /* webpackChunkName: "Settings-Account-Return" */ "@/views/settings/account/Return.vue"
                  )
              },
              {
                path: "refresh",
                name: "Settings-Account-Refresh",
                component: () =>
                  import(
                    /* webpackChunkName: "Settings-Account-Refresh" */ "@/views/settings/account/Refresh.vue"
                  )
              }
            ]
          },
          {
            path: "configuration",
            name: "Settings-Configuration",
            component: () =>
              import(
                /* webpackChunkName: "SiteSettings" */ "@/views/settings/SiteSettings.vue"
              )
          },
          {
            path: "memberships",
            name: "Memberships",
            component: () =>
              import(
                /* webpackChunkName: "Memberships" */ "@/views/settings/Memberships.vue"
              )
          },
          {
            path: "subscription",
            name: "Subscription",
            component: () =>
              import(
                /* webpackChunkName: "Settings-Subscription" */ "@/views/settings/Settings-Subscription.vue"
              )
          }
        ]
      },
      {
        path: "/reports",
        name: "Reports",
        component: () =>
          import(/* webpackChunkName: "Reports" */ "@/views/Reports.vue"),
        children: [
          {
            path: "programs",
            name: "Reports - Program Payments",
            alias: ["/"],
            component: () =>
              import(
                /* webpackChunkName: "ProgramPaymentsReport" */ "@/views/reports/ProgramPaymentsReport.vue"
              )
          },
          {
            path: "private",
            name: "Reports - Private Lessons",
            component: () =>
              import(
                /* webpackChunkName: "ProfilePaymentsReport" */ "@/views/reports/PrivatePaymentsReport.vue"
              )
          },
          {
            path: "drop-ins",
            name: "Reports - Drop Ins",
            component: () =>
              import(
                /* webpackChunkName: "DropInPaymentsReport" */ "@/views/reports/DropInPaymentsReport.vue"
              )
          },
          {
            path: "coach",
            name: "Reports - Coach Payment",
            component: () =>
              import(/* webpackChunkName: "UnReady" */ "@/views/UnReady.vue")
          }
        ]
      },
      {
        path: "payments",
        name: "Payments",
        component: () =>
          import(/* webpackChunkName: "Payments" */ "@/views/Payments.vue"),
        children: [
          {
            path: "programs",
            alias: ["/"],
            name: "Payments - Programs",
            component: () =>
              import(
                /* webpackChunkName: "Run Program Payments" */ "@/views/programs/Directory.vue"
              ),
            children: [
              {
                path: "run/:id",
                name: "Payments - Program - ",
                component: () =>
                  import(
                    /* webpackChunkName: "Payment" */ "@/views/programs/RunProgramPayment.vue"
                  )
              }
            ]
          },
          {
            path: "private-lessons",
            name: "Payments - Private Lessons",
            component: () =>
              import(
                /* webpackChunkName: "RunPrivateLessonPayments" */ "@/views/payments/RunPrivateLessonPayments.vue"
              )
          },
          {
            path: "drop-ins",
            name: "Payments - Drop Ins",
            component: () =>
              import(
                /* webpackChunkName: "RunDropInPayments" */ "@/views/payments/RunDropInPayments.vue"
              )
          }
        ]
      },
      {
        path: "broadcast",
        name: "Broadcast",
        component: () =>
          import(/* webpackChunkName: "Broadcast" */ "@/views/Broadcast.vue"),
        children: [
          {
            path: "send",
            name: "Send Broadcast",
            component: () =>
              import(
                /* webpackChunkName: "SendBroadcast" */ "@/views/messaging/SendBroadcast.vue"
              )
          },
          {
            path: "sent",
            name: "Sent",
            component: () =>
              import(
                /* webpackChunkName: "SendBroadcast" */ "@/views/messaging/SentBroadcast.vue"
              )
          }
        ]
      }
    ]
  },
  {
    path: "/auth",
    name: "Auth",
    component: () =>
      import(/* webpackChunkName: "Auth" */ "@/views/auth/Auth.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        alias: "/",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/auth/Login.vue")
      },
      {
        path: "register",
        name: "Register",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/auth/Login.vue"),
        props: {
          isRegisterPage: true
        }
      }
    ]
  },
  {
    path: "/register",
    name: "Register | Coaches",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/auth/Register.vue"),
    children: [
      {
        path: "coaches",
        name: "Register - Coach",
        component: () =>
          import(
            /* webpackChunkName: "Register-Coach" */ "@/views/auth/RegisterCoach.vue"
          )
      },
      {
        path: "players",
        name: "Register - Player",
        component: () =>
          import(
            /* webpackChunkName: "Register-Player" */ "@/views/auth/RegisterPlayer.vue"
          )
      }
    ]
  },
  {
    path: "/terms",
    component: () => import(/* webpackChunkName: "Terms" */ "@/views/Terms.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
