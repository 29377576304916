









































import Component, { mixins } from "vue-class-component";
import NavLink from "@/components/Navigation/NavLink.vue";
import Menu from "@/assets/icons/saxcons/menu-linear.svg";
import Drawer from "@/components/Navigation/Drawer.vue";
import { MenuItem } from "@/components/Navigation/types";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { Ref, Watch } from "vue-property-decorator";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CurrentUserMixin } from "@/mixins/Helpers";
import MobileDrawer from "./MobileDrawer.vue";

@Component({
  components: {
    MobileDrawer,
    NavLink,
    Menu,
    Drawer,
    VueBottomSheet
  }
})
export default class Navigation extends mixins(
  ResponsiveMixin,
  CurrentUserMixin
) {
  private activeRoute: number;
  private drawerValue: boolean;
  @Ref("bottomSheet")
  private bottomSheet!: VueBottomSheet | undefined;
  constructor() {
    super();
    this.activeRoute = 0;
    this.drawerValue = false;
  }

  private get menuItems(): Array<MenuItem> {
    const baseRoutes: Array<MenuItem> = [
      {
        path: "/calendar",
        name: "Calendar",
        forMobile: true,
        text: "Schedule"
      },
      {
        path: "/programs",
        name: "Programs",
        forMobile: true,
        featureNeeded: "programs",
        skip: !this.$store.getters.featureFlags.programs
      },
      {
        path: "/coaches",
        name: "Coaches",
        forMobile: true,
        skip: (() => {
          if (
            this.$store.getters.featureFlags["coaches-directory-for-coaches"]
          ) {
            // allow even if user is coach
            return false;
          } else if (this.isCurrentUserOnlyCoach) {
            return true;
          }
          return false;
        })()
      },
      {
        path: "/courts",
        name: "Courts",
        forMobile: true,
        skip: !this.$store.getters.featureFlags.courts
      },
      {
        path: (() => {
          if (this.isCurrentUserOnlyCoach) {
            return "/payments/private-lessons";
          } else {
            return "/payments";
          }
        })(),
        name: "Payments",
        skip: (() => {
          if (
            this.currentUser?.permissions.hasCoachAccess ||
            this.currentUser?.permissions.hasAdminAccess
          ) {
            return !this.$store.getters.featureFlags.payments;
          }
          return true;
        })()
      },
      {
        path: (() => {
          if (this.currentUser?.permissions.hasCoachAccess) {
            return "/reports/private";
          }
          return "/reports";
        })(),
        name: "Reports",
        featureNeeded: "reports"
      },
      {
        path: "/broadcast",
        name: "Broadcast",
        requiresAdmin: true
      },
      {
        path: "/players",
        name: "Players",
        skip: (() => {
          if (!this.$store.getters.featureFlags["coach-players"]) {
            return true;
          }
          if (this.isCurrentUserOnlyCoach) {
            return false;
          }
          return true;
        })()
      },
      {
        path: "/users",
        name: "Users",
        requiresAdmin: true,
        skip: (() => {
          if (this.currentUser?.permissions.hasAdminAccess) {
            return false;
          } else {
            return this.$store.getters.featureFlags["users-admin"];
          }
        })()
      },
      {
        path: "/settings",
        name: "Settings",
        forMobile: true,
        skip: (() => {
          if (this.$store.getters.featureFlags["coach-merchants"]) {
            return !this.isCurrentUserOnlyCoach;
          } else {
            return !this.currentUser?.permissions.hasAdminAccess;
          }
        })()
      }
    ];
    return baseRoutes.filter((m) => {
      if (
        m.featureNeeded &&
        !this.$store.getters.featureFlags[m.featureNeeded]
      ) {
        return false;
      }
      if (m.requiresAdmin) {
        return !!this.currentUser?.permissions?.hasAdminAccess;
      }
      if (m.skip) {
        return false;
      }
      return true;
    });
  }

  get mobileBarItems(): Array<MenuItem> {
    return this.menuItems.slice(0, 4);
  }

  get mobileBarPadding(): string {
    if (this.mobileBarItems.length < 4) {
      return `12px ${(4 - this.mobileBarItems.length) * 24}px`;
    } else {
      return "12px 12px";
    }
  }

  @Watch("drawerValue")
  private watchDrawer() {
    if (this.drawerValue) {
      this.$router.push(`${this.$route.path}?openWindow=${this.drawerValue}`);
    } else {
      if (this.$route.query.openWindow === "true") {
        this.$router.back();
      }
    }
  }

  @Watch("$route.fullPath")
  private watchRoute() {
    if (this.$route.query.openWindow === "true") {
      if (!this.drawerValue) {
        this.drawerValue = true;
      }
      if (this.bottomSheet) {
        this.bottomSheet.open();
      }
    } else {
      if (this.drawerValue) {
        this.drawerValue = false;
      }
      if (this.bottomSheet) {
        this.bottomSheet.close();
      }
    }
  }

  mounted() {
    if (this.$route.query.openWindow === "true") {
      this.$router.replace(
        this.$route.fullPath.replace("openWindow=true", "openWindow=false")
      );
    } else if (this.$route.path !== "/") {
      this.menuItems.forEach((m, i) => {
        if (this.$route.path.includes(m.path)) {
          this.activeRoute = i;
        }
      });
    }
  }
}
