/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "Event: Ready --> App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration: ServiceWorkerRegistration) {
      console.log("Event: Registered --> Service worker has been registered.");
      setInterval(() => {
        registration.update();
      }, 10000);
    },
    cached() {
      console.log("Event: Cached --> Content has been cached for offline use.");
    },
    updatefound() {
      console.log("Event: UpdatedFound --> New content is downloading.");
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log("Event:- Updated --> Service worker has been Updated");
      const event = new CustomEvent("swUpdated", { detail: registration });
      // document.dispatchEvent(event);
    },
    offline() {
      console.log("Event:- Offline --> Device Detected to be offline");
      document.dispatchEvent(new Event("offline"));
    },
    error(error) {
      console.error(
        "Event:- Error --> Error during service worker registration:",
        error
      );
    }
  });
}
