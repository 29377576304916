import { AutoCompleteItem } from "@/components/Inputs/mixins";
import { DB } from "@/firebase";
import { forFirestore, fromFirestore } from "@/utils/parser";
import { getFinalArray } from "@/utils/validation";
import {
  Event,
  EVENTS_TABLE_NAME,
  Program,
  ProgramEntity,
  ProgramMakeupDetails,
  PROGRAMS_MAKEUP_TABLE_NAME,
  PROGRAMS_TABLE_NAME
} from "@sportango/backend";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  updateDoc,
  where
} from "firebase/firestore";
import { chunk } from "lodash";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { getProgramEventsQuery } from "./events";

export function getPlayerIds(programs: Array<Program>): Array<string> {
  const res = [
    ...programs.map((p) => {
      let res: Array<string> = [];
      if (p.coaches) {
        res = [...res, ...p.coaches];
      }
      if (p.programPlayers) {
        res = [
          ...res,
          ...p.programPlayers
            .map((pl) => pl.uid || "")
            .filter((pl) => pl.length !== 0)
        ];
      }
      if (p.playerRequests) {
        res = [
          ...res,
          ...p.playerRequests
            .map((pl) => pl.uid || "")
            .filter((pl) => pl.length !== 0)
        ];
      }
      return res;
    })
  ];
  if (res.length > 0) {
    return res.reduce((o, n) => {
      let res: Array<string> = [];
      if (o) {
        res = [...res, ...o];
      }
      if (n) {
        res = [...res, ...n];
      }
      return res;
    });
  }
  return [];
}

export interface GetProgramsPayload {
  startDate: Date;
  sport?: string;
}

export const programActions: ActionTree<RootState, RootState> = {
  async getPrograms({ commit, dispatch }, data: GetProgramsPayload) {
    commit("programs", []);
    const eventsQuery = query(
      collection(DB, EVENTS_TABLE_NAME),
      ...getProgramEventsQuery({
        type: "week",
        refDate: data.startDate.toISOString()
      })
    );
    const { docs } = await getDocs(eventsQuery);
    const records = docs.map((d) => {
      return fromFirestore<Event>(d, "id");
    });
    const programIds = Array.from(new Set(records.map((r) => r.parentItem)));
    await dispatch("getProgramById", programIds);
  },
  async getAllPrograms({ commit, getters, dispatch }) {
    const q = query(collection(DB, PROGRAMS_TABLE_NAME), orderBy("startDate"));
    const { docs } = await getDocs(q);
    const records = docs.map((d) => {
      const parsed = fromFirestore<Program>(d, "id");
      return parsed;
    });
    commit("programs", getFinalArray<Program>(getters.programs, records, "id"));
    await dispatch("getUsersById", getPlayerIds(records));
  },

  async getProgramById(
    { commit, getters, dispatch },
    ids: string | Array<string>
  ) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    ids = Array.from(
      new Set(
        ids.filter(
          (id) => getters.programs.find((p) => p.id === id) === undefined
        )
      )
    );
    if (ids.length > 0) {
      if (ids.length > 5) {
        await Promise.allSettled(
          chunk(ids, 5).map((u) => dispatch("getProgramById", u))
        );
      } else {
        const q = query(
          collection(DB, PROGRAMS_TABLE_NAME),
          where(documentId(), "in", ids)
        );
        const programs = (await getDocs(q)).docs.map((p) =>
          fromFirestore<Program>(p, "id")
        );
        commit(
          "programs",
          getFinalArray<Program>(getters.programs, programs, "id")
        );
        await dispatch("getUsersById", getPlayerIds(programs));
      }
    }
  },

  async getLatestProgram({ commit, getters }, id: string) {
    const program = fromFirestore<Program>(
      await getDoc(doc(collection(DB, PROGRAMS_TABLE_NAME), id)),
      "id"
    );
    commit(
      "programs",
      getters.programs.map((p) => (p.id === program.id ? program : p))
    );
    const programDetailsDoc = await getDoc(
      doc(collection(DB, PROGRAMS_MAKEUP_TABLE_NAME), id)
    );
    if (programDetailsDoc.exists()) {
      const programDetails = fromFirestore<ProgramMakeupDetails>(
        programDetailsDoc,
        "id"
      );
      commit("programMakeups", {
        [id]: programDetails
      });
    }
  },

  async refreshProgram({ commit, getters, dispatch }, programId: string) {
    const programDoc = await getDoc(
      doc(collection(DB, PROGRAMS_TABLE_NAME), programId)
    );
    const program = fromFirestore<Program>(programDoc, "id");
    const finalArray =
      getters.programs.length > 0
        ? getters.programs.map((p) => {
            if (p.id === program.id) {
              return program;
            }
            return p;
          })
        : [program];
    commit("programs", finalArray);
    await dispatch("getUsersById", getPlayerIds([program]));
  },

  async addProgram(
    { commit, getters, dispatch },
    payload: Partial<Program>
  ): Promise<void> {
    const response = await addDoc(
      collection(DB, PROGRAMS_TABLE_NAME),
      forFirestore(payload)
    );
    commit("programs", [
      ...getters.programs,
      {
        ...payload,
        id: response.id
      }
    ]);
    if (payload.programPlayers || payload.playerRequests) {
      await dispatch("getUsersById", [
        ...(payload.programPlayers || []).map((p) => p.uid),
        ...(payload.playerRequests || []).map((p) => p.uid)
      ]);
    }
  },

  async updateProgram(
    { commit, getters, dispatch },
    payload: Partial<Program>
  ) {
    await updateDoc(
      doc(collection(DB, PROGRAMS_TABLE_NAME), payload.id),
      forFirestore(payload)
    );
    commit(
      "programs",
      getters.programs.map((p) => {
        if (p.id === payload.id) {
          return new ProgramEntity({
            ...p,
            ...payload
          });
        }
        return new ProgramEntity(p);
      })
    );
    if (payload.programPlayers) {
      await dispatch(
        "getUsersById",
        payload.programPlayers.map((p) => p.uid)
      );
    }
  },

  async searchPrograms(
    { commit, getters },
    payload: { searchString: string; maxResults: number }
  ) {
    const programs = await findPrograms(
      payload.searchString,
      payload.maxResults
    );
    commit(
      "programs",
      getFinalArray<Program>(getters.programs, programs, "id")
    );
  },

  async deleteProgram({ commit, getters }, id: string) {
    await deleteDoc(doc(collection(DB, PROGRAMS_TABLE_NAME), id));
    commit(
      "programs",
      getters.programs.filter((p) => p.id !== id)
    );
  }
};

export async function findProgramsForAutocomplete(
  searchString: string,
  maxResults: number
): Promise<Array<AutoCompleteItem>> {
  return (await findPrograms(searchString, maxResults)).map((p) => {
    const res: AutoCompleteItem = {
      text: p.name || "",
      value: p.id || ""
    };
    return res;
  });
}

export async function findPrograms(
  searchString: string,
  maxResults: number
): Promise<Array<Program>> {
  const q = query(
    collection(DB, PROGRAMS_TABLE_NAME),
    orderBy("name"),
    startAt(searchString),
    endAt(`${searchString}\uf8ff`),
    limit(maxResults)
  );
  const { docs } = await getDocs(q);
  const programs = docs.map((d) => fromFirestore<Program>(d, "id"));
  return programs;
}
